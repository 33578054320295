.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  p, ul {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  ul {
    padding-left: 20px;
  }
  